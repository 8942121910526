<template>
  <Navigation />


  <div class="main-content container">

    <div class="d-flex justify-content-center align-items-center">
      <div class="mt-5 loader" v-if="isLoading"></div>
    </div>
    <div class="card mb-4 dataCard">
       
      <h2 class="text-center mt-3">PLAYER QUEUE</h2>

      <div class="card-body tabledata">
        <div class="form-group row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              v-model="searchTerm"
              placeholder="Search "
            />
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-sm addbtn rounded-pill"
              @click="resetModalData"
              data-bs-toggle="modal"
              data-bs-target="#waitlistModal"
            >
              <span>Add</span>
            </button>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-sm clearbtn rounded-pill"
              data-bs-toggle="modal"
              data-bs-target="#confirmationModal"
            >
              <span>Clear Data</span>
            </button>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table mt-3">
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Players</th>
                <th>Arrival Time</th>
                <th>Wait Time</th>
                <th>Notes</th>
                <th>SMS Sent</th>
                <th>Status</th>
                <th class="text-center">Actions</th>
                
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in paginatedFilteredwaitlist"
                v-bind:key="index"
              >
                <td>{{ item.id }}</td>
                <td>
                  <div class="player">
                    {{ item.name }}
                  </div>
                  <div>
                    {{ item.mobile }}
                  </div>
                </td>

                <td>{{ item.players }}</td>
                <td>
                  <div class="arrival">
                    <!-- {{ item.arrival_time }} -->

                    {{extractTime(item.arrival_time)}}
                  </div>
                  <div
                    class="playert"
                    :style="{ color: getTimeColor(item.rem_time) }"
                  >
                    {{ item.rem_time }} mins
                  </div>
                </td>
                <td>{{ item.wait_time }} Mins</td>
                <td>
                  <div class="custom-btn">
                    <button
                      type="button"
                      class="nav-link btn btn-success"
                      data-bs-toggle="modal"
                      :data-bs-target="'#notesModal-' + item.id"
                    >
                      <div class="icon-wrapper">
                        <font-awesome-icon
                          icon="fa-solid fa-eye"
                          class="mx-1 ab"
                        />
                      </div>
                    </button>
                  </div>
                </td>
                 <td>{{displaySmsDuration(item.sms_duration)}}</td>
                 

                <td :class="getStatusClass(item.status)">
                  {{ getStatusText(item.status) }}
                </td>

                <td class="">
                  <div class="row">
                    <div class="col p-1">
                      <form
                        @submit.prevent="
                          sendAlert(item.mobile, item.arrival_time,index,item.id)
                        "
                      >
                        <div class="custom-btn">
                          <button
                            type="submit"
                            class="nav-link btn btn-warning"
                          >
                            <div class="icon-wrapper">
                              <font-awesome-icon
                                icon="fa-solid fa-message"
                                class="mx-1 ab"
                              />
                            </div>
                          </button>
                        </div>
                      </form>
                    </div>
                    <div class="col p-1">
                      <div class="custom-btn">
                        <button
                          @click="
                            requeue(
                              item.mobile,
                              item.arrival_time,
                              item.wait_time,
                              index
                            )
                          "
                          class="nav-link btn btn-success"
                          title="Requeue"
                        >
                          <div class="icon-wrapper">
                            <font-awesome-icon
                              icon="fa-solid fa-check"
                              class="mx-1 ab"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="col p-1">
                      <div class="custom-btn">
                        <button
                          @click="purge(item.mobile, item.arrival_time, index)"
                          class="nav-link btn btn-danger"
                          title="Purge"
                        >
                          <div class="icon-wrapper">
                            <font-awesome-icon
                              icon="fa-solid fa-ban"
                              class="mx-1 ab"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="col p-1">
                      <div class="custom-btn">
                        <button
                          class="nav-link btn btn-primary"
                          title="edit"
                          @click="openModal(item.id)"
                          data-bs-toggle="modal"
                          data-bs-target="#updatewaitlistModal"
                        >
                          <div class="icon-wrapper">
                            <font-awesome-icon
                              icon="fa-solid fa-pen-to-square"
                              class="mx-1 ab"
                            />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-nav"
            @click="currentPage--"
            :disabled="currentPage === 1"
          >
            Previous
          </button>
          <span
            >{{ currentPage }} of
            {{ Math.ceil(filteredwaitlist.length / itemsPerPage) }}</span
          >
          <button
            class="btn btn-nav"
            @click="currentPage++"
            :disabled="
              currentPage === Math.ceil(filteredwaitlist.length / itemsPerPage)
            "
          >
            Next
          </button>
        </div>
      </div>
    </div>

    <!-- Add to Waitlist Modal -->

    <div
      class="modal fade"
      id="waitlistModal"
      tabindex="-1"
      aria-labelledby="waitlistModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="waitlistModalLabel">Add to Waitlist</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addToWaitlist">
              <div class="mb-3">
                <label for="nameInput" class="form-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="nameInput"
                  placeholder="Enter name"
                  v-model="name"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="mobileInput" class="form-label">Phone Number</label>
                <input
                  type="text"
                  class="form-control"
                  id="mobileInput"
                  placeholder="Enter Phone Number"
                  v-model="mobile"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="player" class="form-label">No. Of Players</label>
                <input
                  type="number"
                  class="form-control"
                  id="player"
                  placeholder="Enter No of Players"
                  v-model="player"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="arrivalTimeInput" class="form-label"
                  >Arrival Time</label
                >
                <input
                  type="datetime-local"
                  class="form-control"
                  id="arrivalTimeInput"
                  v-model="arrivalTime"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="waitTimeInput" class="form-label">Wait Time</label>
                <input
                  type="number"
                  class="form-control"
                  id="waitTimeInput"
                  placeholder="Enter wait time in minutes"
                  v-model="waitTime"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="notesTextarea" class="form-label">Notes</label>
                <textarea
                  class="form-control"
                  id="notesTextarea"
                  placeholder="Enter your notes here"
                  v-model="notes"
                  
                ></textarea>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-success">Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--update Modal -->
     <div
      class="modal fade"
      id="updatewaitlistModal"
      tabindex="-1"
      aria-labelledby="updatewaitlistModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updatewaitlistModalLabel">Add to Waitlist</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateWaitlist">
              <div class="mb-3">
                <label for="nameInput" class="form-label">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="nameInput"
                  placeholder="Enter name"
                  v-model="name"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="mobileInput" class="form-label">Phone Number</label>
                <input
                  type="text"
                  class="form-control"
                  id="mobileInput"
                  placeholder="Enter Phone Number"
                  v-model="mobile"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="player" class="form-label">No. Of Players</label>
                <input
                  type="text"
                  class="form-control"
                  id="player"
                  placeholder="Enter No of Players"
                  v-model="player"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="arrivalTimeInput" class="form-label"
                  >Arrival Time</label
                >
                <input
                  type="datetime-local"
                  class="form-control"
                  id="arrivalTimeInput"
                  v-model="arrivalTime"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="waitTimeInput" class="form-label">Wait Time</label>
                <input
                  type="number"
                  class="form-control"
                  id="waitTimeInput"
                  placeholder="Enter wait time in minutes"
                  v-model="waitTime"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="notesTextarea" class="form-label">Notes</label>
                <textarea
                  class="form-control"
                  id="notesTextarea"
                  placeholder="Enter your notes here"
                  v-model="notes"
                  
                ></textarea>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-success">Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <div
      class="modal fade"
      id="confirmationModal"
      tabindex="-1"
      aria-labelledby="confirmationModalLabel"
      aria-hidden="true"
      v-if="showConfirmationModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="confirmationModalLabel">
              Confirmation
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Do you wish to purge today's data?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="purgeWaitlist"
              data-bs-dismiss="modal"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- note modal -->

    <div
      v-for="item in waitListData"
      :key="item.id"
      class="modal fade"
      :id="'notesModal-' + item.id"
      tabindex="-1"
      aria-labelledby="notesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="notesModalLabel">
              Notes for {{ item.name }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>{{ item.notes }}</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../components/Navigation.vue";

import axios from "axios";
import moment from "moment";

export default {

   components: {
    Navigation,
  },

  data() {
    return {
      name: "",
      arrivalTime: "",
      waitTime: "",
      remTime: "",
      player: "",
      mobile: "",
      originalMobile: "",
      waitListData: [],
      timer: "",
      searchTerm: "",
      currentPage: 1,
      itemsPerPage: 6,
      selectedRow: "",
      notes: "",
      showConfirmationModal :true,
      isLoading:"",
      erroResponse:""
    };
  },

  computed: {

    //filter method

    filteredwaitlist() {
      if (!Array.isArray(this.waitListData)) {
        return [];
      }
      const searchTermLower = this.searchTerm.toLowerCase();
      const filtered = this.waitListData.filter((item) => {
      const nameLower = item.name.toLowerCase();
      const statusTextLower = this.getStatusText(item.status).toLowerCase();
      return (
          nameLower.includes(searchTermLower) ||
          item.mobile.includes(this.searchTerm) ||
          item.players.toString().includes(this.searchTerm) ||
          statusTextLower.includes(searchTermLower)
        );
      });
      return filtered.slice();
    },


    //pahination

    paginatedFilteredwaitlist() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredwaitlist.slice(start, end);
    },

    getTimeColor() {
      return (remTime) => {
      return remTime >= 5 ? "green" : "red";
      };
    },


    
  },
 

  mounted() {
    this.fetchWaitlist();
  },

  methods: {
    //add an entry to the waitlist
    async addToWaitlist() {
      const formattedArrivalTime = moment(this.arrivalTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const waitlistItem = {
        name: this.name,
        arrival_time: formattedArrivalTime,
        wait_time: this.waitTime,
        players: this.player,
        mobile: this.mobile,
        notes: this.notes,
      };
      try {
       
        const token = localStorage.getItem("token");

          $('#waitlistModal').modal('hide');
          this.waitListData.push(waitlistItem);
           await axios.post(
            `${process.env.VUE_APP_BASE_URL}waitlist`,
            waitlistItem,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          this.addedAlert();
          this.fetchWaitlist()
           
        } catch (error) {
          
        if(error.response){
        this.waitListData.pop()
        this.errorResponse = error.response.data
        this.errorAlert()
        this.fetchWaitlist()

        }else{
        this.waitListData.pop()
        this.errorAlertTwo()
        }
      
      }
    },
    
    //update waitlist

    async updateWaitlist(){
      const formattedArrivalTime = moment(this.arrivalTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const waitlistItem = {
        name: this.name,
        arrival_time: formattedArrivalTime,
        wait_time: this.waitTime,
        players: this.player,
        mobile: this.mobile,
        notes: this.notes,
      };

      try{
        const token = localStorage.getItem("token");

          $('#updatewaitlistModal').modal('hide')
        
           await axios.put(
            `${process.env.VUE_APP_BASE_URL}waitlist/${this.selectedRow}`,
            waitlistItem,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          this.updateAlert();
          this.fetchWaitlist()
         } catch(error){

         if(error.response){
        this.errorResponse = error.response.data
        this.errorAlert()
        this.fetchWaitlist()

        }else{
        this.errorAlertTwo()
        }

         }
      },

      //fetch updated waitlist

    async fetchWaitlist() {
      
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}waitlists`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        this.waitListData = response.data;

      } catch (error) {
         this.tableError()
      }
    },

    displaySmsDuration(smsDuration) {
      if ( smsDuration === null || smsDuration === undefined) {
        return 'No SMS SENT';
      }else if(smsDuration === -1){
        return 'Over 24hrs';
      }else if (smsDuration === 0) {
    return 'Just now';
      }
      else if(smsDuration >=60){
       const smsMin = Math.floor(smsDuration / 60);
        return `${smsMin} Min ago`;
      }
      else {
        return `${smsDuration} Seconds ago`;
      }

    },

    //assign data to inputs when modal opens
    openModal(id) {
      this.selectedRow = id;

      const selectedRow = this.waitListData.find((item) => item.id === id);
      this.name = selectedRow.name;
      this.mobile = selectedRow.mobile;
      this.player = selectedRow.players;
      this.arrivalTime = selectedRow.arrival_time;
      this.waitTime = selectedRow.rem_time;
      this.notes = selectedRow.notes;
    },

   //reset Modal data
    resetModalData() {
      this.name = "";
      this.mobile = "";
      this.player = "";
      this.arrivalTime = "";
      this.waitTime = "";
    },


// purge a single entry
    async purge(mobile, arrivalTime, itemId) {
      this.waitListData.splice(itemId, 1);
      this.purgeAlert();
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}purge`,
          {
            mobile: mobile,
            arrival_time: arrivalTime,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ); 

        this.fetchWaitlist();
        
      } catch (error) {
        this.purgeError()

      }
    },


//return an entry to the queue
    async requeue(mobile, arrivalTime, waitTime,index) {

      this.waitListData[index].status = 0
      this.requeueAlert();
      
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}requeue`,
          {
            mobile: mobile,
            arrival_time: arrivalTime,
            wait_time: waitTime,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        this.fetchWaitlist();

      } catch (error) {
         if(error.response){
         this.errorResponse = error.response.data
          this.errorAlert()

        }else{
        this.errorAlertTwo()
        }
      }
    },
     
     //update nuemrical value of status to text
    getStatusText(status) {
      if (status === 0) {
        return "In Progress";
      } else if (status === 1) {
        return "Completed";
      } else if (status === 2) {
        return "Purged";
      }
      return "";
    },

    //update color style of status depending on value
    getStatusClass(status) {
      if (status === 0) {
        return "text-success";
      } else if (status === 1) {
        return "text-info";
      } else if (status === 2) {
        return "text-danger";
      }
      return "";
    },

     //extract time only
    extractTime(datetime) {
  if (datetime && typeof datetime === 'string') {
    const parts = datetime.split(" ");
    const time = parts[1];
    return time;
  }
  return null;
},

    //send SMS alert

    async sendAlert(mobile, arrivalTime, indexId,waitlistId) {
      this.waitListData[indexId].status = 1
      this.smsAlert();
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}sendAlert`,
          {
            mobile: mobile,
            arrival_time: arrivalTime,
            waitlistId : waitlistId
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        this.jdata = response.data
        this.fetchWaitlist()
          
      } catch (error) {
        this.smserrorAlert()
      }
    },


    //remove the entire list from view
    async purgeWaitlist() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}purgeWaitlist`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        this.fetchWaitlist();
        this.purgeAlert();
      } catch (error) {
        this.purgeError()
      }
    },

    //alerts

    requeueAlert(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 3000,
         icon: 'success',
         title: 'Pins Notification',
         text: 'Entry queued'
       });
     },

    smsAlert(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 3000,
         icon: 'success',
         title: 'Pins Notification',
         text: 'Message Sent'
       });
     }
    ,

    purgeAlert(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 3000,
         icon: 'success',
         title: 'Pins Notification',
         text: 'Entry Purged'
       });
     },

      addedAlert(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 3000,
         icon: 'success',
         title: 'Pins Notification',
         text: 'Added Entry to waitlist'
       });
     },

     updateAlert(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 3000,
         icon: 'success',
         title: 'Pins Notification',
         text: 'Entry Updated'
       });
     },

     errorAlert(){
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6000,
        icon: "warning",
        title: "ERROR",
        text: `${JSON.stringify(this.errorResponse.error)}`,
        showCancelButton: 'true'
      });

    },
     errorAlertTwo(){
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6000,
        icon: "warning",
        title: "ERROR",
        text: "Something went wrong",
        showCancelButton: 'true'
      });

    },
     smserrorAlert(){
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6000,
        icon: "warning",
        title: "ERROR",
        text: "SMS Could not be sent. Check balance or SMS provider errors",
        showCancelButton: 'true'
      });

    },

     tableError(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 6000,
         icon: 'warning',
         title: 'ERROR',
         text: 'Something went wrong fetching queue data',
          showCancelButton: 'true'
       });
     },

     purgeError(){
      this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 6000,
         icon: 'warning',
         title: 'ERROR',
         text: 'Something went wrong Purging the entry',
        showCancelButton: 'true'
       });
     },
     
  },


  
};
</script>

<style scoped>
.bod {
  border: 2px solid green;
}

.tabledata {
  overflow-y: auto;
  max-width: 100%;
}

h2,
th {
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

.btn-nav {
  background-color: #f9c54d;
  color: white;
}

.btn.btn-nav:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

th,
td {
  font-size: smaller;
}

.btn.resend:hover {
  color: white;
}

.ab {
  color: white;
}

.icon-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dataCard {
  margin-top: 10%;
}

.addbtn {
  display: inline-block;
  border-radius: 4px;
  background-color: #f9c54d;
  border: none;
  color: #ffffff;
  text-align: center;

  width: 130px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.addbtn:hover {
  background: #198754;
}
.addbtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.addbtn span:after {
  content: "+";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}

.addbtn:hover span {
  padding-right: 15px;
}

.addbtn:hover span:after {
  opacity: 1;
  right: 0;
}

.clearbtn {
  display: inline-block;
  border-radius: 4px;
  background-color: #ff0606;
  border: none;
  color: #ffffff;
  text-align: center;

  width: 130px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.clearbtn:hover {
  background: #871919;
}
.clearbtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.clearbtn span:after {
  content: "-";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}

.clearbtn:hover span {
  padding-right: 15px;
}

.clearbtn:hover span:after {
  opacity: 1;
  right: 0;
}

table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}
</style>