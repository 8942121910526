<template>
  <section class="login-section text-center container">
    <div class="card mb-3 mt-5 shadow bg-dark">
      <div class="row g-0 d-flex align-items-center">
        <div class="col-lg-4 d-block d-lg-flex justify-content-center">
          <img
            src="../assets/pinsbg.png"
            alt="Bowling pins"
            class="img-fluid"
            width="200"
            height="200"
          />
        </div>
        <div class="col-lg-8">
          <div class="card-body py-5 px-md-5">
            <h2 class="text-white">User Login</h2>

            <form class="" @submit.prevent="submitForm()">
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="inputEmail"
                  type="email"
                  placeholder="Email Address"
                  v-model="email"
                  
                />
                <label for="inputUsername">Email</label>
              </div>
              <div class="form-floating mb-3">
                <input
                  class="form-control"
                  id="inputPassword"
                  type="password"
                  placeholder="Password"
                  v-model="password"
                  
                />
                <label for="inputPassword">Password</label>
              </div>

              <div
                class="d-flex align-items-center justify-content-between mt-4 mb-0"
              >
                <button type="submit" class="btn btn-block mb-4 w-100 signin">
                  <span>Sign in</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { ref, inject } from "vue";
import store from "../store";
import router from "../router";

export default {
  data() {
    return {
      email: "",
      password: "",
      token: "null",
    };
  },
  methods: {
    loginAlert() {
      if (this.errorResponse && this.errorResponse.error) {
        const errorText = Object.entries(this.errorResponse.error)
          .map(([key, value]) => `${key}: ${value}`)
          .join("\n");
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6000,
        icon: "warning",
        title: "LOGIN ERROR",
        text: `${JSON.stringify(this.errorResponse.error)}`,
        showCancelButton: true,
      });
    }},
    errorLogin() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6000,
        icon: "warning",
        title: "LOGIN ERROR",
        text: "Something went wrong",
        showCancelButton: true,
      });
    },
    async submitForm() {
      
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_BASE_URL}login`,
          {
            email: this.email,
            password: this.password,
          }
        );
        store.dispatch("setToken", data.token);

        router.push({ name: "Dashboard" });
      } catch (error) {

        if(error.response){
         this.errorResponse = error.response.data
        this.loginAlert()

        }else{
        this.errorLogin()
        }
  
      }
    },
  },
};

</script>


<style scoped>
label,
h2,
span {
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
}

.login-section {
  height: 100vh;
}

.bod {
  border: 2px solid green;
}

.login-section {
  margin-top: 10%;
}
.form-control:focus {
  box-shadow: 0 0 0 2px #f9c54d;
}
/* .btn {
  border-radius: 20px; 
  background: linear-gradient(45deg, #f9c54d, #eb6482);
  color: #fff;
  transition: box-shadow 0.3s ease-in-out;
  border: none;
  padding: 10px 20px;

}

.btn:hover {
  box-shadow: 0 0 0 2px #fff; 
} */

.btn {
  --color: #f9c54d;
  --color2: rgb(10, 25, 30);
  padding: 0.8em 1.75em;
  background-color: transparent;
  border-radius: 6px;
  border: 0.3px solid var(--color);
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  font-weight: 300;
  font-size: 17px;
  font-family: "Roboto", "Segoe UI", sans-serif;
  text-transform: uppercase;
  color: var(--color);
}

.btn::after,
.btn::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  transform: skew(90deg) translate(-50%, -50%);
  position: absolute;
  inset: 50%;
  left: 25%;
  z-index: -1;
  transition: 0.5s ease-out;
  background-color: var(--color);
}

.btn::before {
  top: -50%;
  left: -25%;
  transform: skew(90deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::before {
  transform: skew(45deg) rotate(180deg) translate(-50%, -50%);
}

.btn:hover::after {
  transform: skew(45deg) translate(-50%, -50%);
}

.btn:hover {
  color: var(--color2);
}

.btn:active {
  filter: brightness(0.7);
  transform: scale(0.98);
}
</style>