<template>
  <Navigation />
  <div class="container text-container">
    <div class="d-flex justify-content-center align-items-center">
      <div class="mt-5 loader" v-if="isLoading"></div>
    </div>
    <div class="row mt-5" v-if="loaded">

      <div class="col-md-6">
        <div class="card shadow bg-warning text-white">
          <div class="card-body">
            <h5 class="card-title text-center">CURRENT ALERT</h5>
            <a data-bs-toggle="modal" data-bs-target="#smsModal">

            <div class="card-body">

              <p class="text-center ellipsis">SMS: {{smsValue}}</p>

            </div>

            </a>
            
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card shadow text-white bg-success">
          <div class="card-body">
            <h5 class="card-title text-center">SMS BALANCE</h5>

            <div class="card-body">

              <p class="text-center">{{smsBalanceValue}} Units</p>

            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-center" v-if="loaded">
      <div class=" w-50 mt-5 smsupdate">
        <div class="card shadow">
          <div class="card-body">
            <h5 class="card-title text-center">UPDATE SMS ALERT</h5>
            <form @submit.prevent="updateSMSAlert(newContent)">
              <div class="mb-3">
                <textarea
                  class="form-control"
                  id="content"
                  rows="5"
                  v-model="newContent"
                ></textarea>
              </div>
              <button type="submit" class="btn btn-warning w-100 text-bold">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    
  </div>

  <!-- Modal -->
<div class="modal fade" id="smsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">SMS ALERT</h5>
      </div>
      <div class="modal-body">
        <p class="text-center">{{smsValue}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


</template>


<script>
import Navigation from "../components/Navigation.vue";
import axios from "axios";

export default {
  components: { Navigation },

  data() {
    return {
      newContent: "",
      smsValue:"",
      smsBalanceValue:"",
      isLoading:"",
      loaded:"",
      errorResponse:""
    };
  },

    created() {
    this.smsContent()
  },

  methods: {
    async updateSMSAlert(newContent) {
    
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}updateSMSAlert`,
          {
            newContent: newContent,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.smsValue = this.newContent
        this.updateAlert();
        this.smsContent()
        this.newContent=""
      } catch (error) {
        if(error.response){
        this.errorResponse = error.response.data
        console.log(this.errorResponse)
        this.contentError()

        }else{
        this.contentErrortwo()
        }
      }
    },

    async smsContent() {
      this.isLoading =true
      this.loaded = false
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_URL}sms`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    this.smsValue = response.data;
    this.smsBalance()
    this.isLoading = false
    this.loaded = true
 
  } catch (error) {
    this.isLoading = false
    this.loaded = true
    this.errorGetsms()
  }
},

 async smsBalance() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${process.env.VUE_APP_BASE_URL}smsbalance`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    this.smsBalanceValue = response.data;
  } catch (error) {
 this.errorGetsms()
  }
},


//alerts

 updateAlert(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 3000,
         icon: 'success',
         title: 'Pins Notification',
         text: 'Message Alert Updated'
       });
     },

  errorGetsms(){

    this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 6000,
         icon: 'warning',
         title: 'ERROR',
         text: 'ERROR fetching SMS and Balance',
         showCancelButton: 'true'
       });

  },
  contentError(){

    this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 6000,
         icon: 'warning',
         title: 'ERROR',
         text: `${JSON.stringify(this.errorResponse.message)}`,
         showCancelButton: 'true'
       });

  },
  contentErrortwo(){

    this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 6000,
         icon: 'warning',
         title: 'ERROR',
         text: 'Something went wrong',
         showCancelButton: 'true'
       });

  }
  }

    
};
</script>

<style scoped>
.text-container {
  margin-top: 10%;
}

p{
  font-size: 20px;
  font-weight: 500;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@media (max-width: 820px) {
  .card {
    margin-top: 5% !important;
    
  }

  .smsupdate{
    width: 100% !important;
  }
}
</style>