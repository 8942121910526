<template>
  <Navigation />
  <div class="container mt-5">
    <div class="d-flex justify-content-center align-items-center">
      <div class="mt-5 loader" v-if="isLoading"></div>
    </div>
    <div class="main-content card mb-4" v-if="loaded">
      <h2 class="text-center mt-3">SMS Report</h2>

      <div class="card-body tabledata">
        <div class="form-group row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              v-model="searchTerm"
              placeholder="Search By Mobile Number"
            />
          </div>
          <div class="col-auto"></div>
        </div>

        <table class="table mt-3 table-responsive-sm">
          <thead>
            <tr>

              <th>Mobile No</th>
              <th>Time Sent</th>
              <th>Time Delivered</th>
              <th>Delivery Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in paginatedOutbox"
              v-bind:key="index"
            >
              <td>{{ item.mobile }}</td>
              <td>{{item.created_at}}</td>
              <td>{{ item.delivery_time }}</td>
              <td :class="getStatusClass(item.delivery_description)">
                {{ getStatusText(item.delivery_description) }}
                </td>

            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-nav"
            @click="currentPage--"
            :disabled="currentPage === 1"
          >
            Previous
          </button>
          <span
            >{{ currentPage }} of
            {{ Math.ceil(filteredOutbox.length / itemsPerPage) }}</span
          >
          <button
            class="btn btn-nav"
            @click="currentPage++"
            :disabled="
              currentPage === Math.ceil(filteredOutbox.length / itemsPerPage)
            "
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../components/Navigation.vue";

import axios from "axios";



export default {
  computed: {
    filteredOutbox() {
      if (!Array.isArray(this.outboxData)) {
        return [];
      }
      const filtered = this.outboxData.filter((item) => {
    
        return (
          item.mobile.includes(this.searchTerm)
        );
      });
      return filtered.slice().reverse();
    },

    paginatedOutbox() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredOutbox.slice(start, end);
    },
  },
  components: {
    Navigation,
  },
  data() {
    return {
  
     
      outboxData: [],
      searchTerm: "",
      currentPage: 1,
      itemsPerPage: 6,
      isLoading:"",
      loaded:""
    };
  },

  mounted() {
    this.fetchOutbox();
  },

  methods: {
    async fetchOutbox() {
      this.loaded = false
      this.isLoading = true
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}outbox`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false
        this.loaded = true
        this.outboxData = response.data.reverse();
      } catch (error) {
        this.outboxErrorAlert()
        this.isLoading = false
        this.loaded = true
      }
    },

     outboxErrorAlert(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 6000,
         icon: 'warning',
         title: 'ERROR',
         text: 'Something went wrong fetching outbox data'
       });
     }
    ,


    getStatusText(status) {
      if (status === "DeliveredToTerminal") {
        return "Delivered";
      } else if (status === "SentToNetwork") {
        return "Pending Dlr";
      } else if (status === "SenderName Blacklisted") {
        return "Blocked Sender";
      }else if(status === "DeliveryImpossible" || status === "AbsentSubscriber" || status === "Failed"){
        return "Failed";
      }
      return "No Dlr";
    },

     getStatusClass(status) {
      if (status === "DeliveredToTerminal") {
        return "text-success";
      } else if (status === "SentToNetwork") {
        return "text-info";
      } 
      return "text-danger";
    },

  
  },
};
</script>

<style scoped>

.main-content {
  margin-top: 10%;
}

.tabledata {
  overflow-y: auto;
  max-width: 100%;
}

h2,
th {
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

.btn-nav {
  background-color: #f9c54d;
  color: white;
}

.btn.btn-nav:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

th,
td {
  font-size: smaller;
}

</style>