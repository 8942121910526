
import { createStore } from 'vuex'

export default createStore({
state: {
    token: null,

}
,
mutations: {
    setToken (state, token) {
    
    state.token = token;
    state.loggedIn = true;
    localStorage.setItem('token', token);

    },
    
    clearUserData (state) {
        state.token = null
        state.loggedIn = false;
        localStorage.removeItem('token');
        }
},
actions: {
    setToken({ commit }, token) {
    
    
    commit('setToken', token)
    
    },
   
        clearUserData({ commit }) {
        commit('clearUserData')
        }
},
getters: {
    isAuthenticated: state => !!state.token
     

},



})
