<template>
  <section class="nav">

    <!-- My Side Nav -->
    <div class="offcanvas offcanvas-start w-25" tabindex="-1" id="offcanvas">
      <div class="offcanvas-header">
        <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas">Menu</h6>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body px-0">
        <ul
          class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-start"
          id="menu"
        >
          <li class="nav-item">
            <router-link to="/" class="nav-link">
              <font-awesome-icon icon="fa-solid fa-house" class="icon" />

              <span class="ms-1 d-none d-sm-inline navname">Home</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/outbox" class="nav-link">
              <font-awesome-icon icon="fa-solid fa-envelope" class="icon" />
              <span class="ms-1 d-none d-sm-inline navname">Outbox</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/recents" class="nav-link">
              <font-awesome-icon icon="fa-solid fa-list" class="icon" />
              <span class="ms-1 d-none d-sm-inline navname">Recents</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/settings" class="nav-link">
              <font-awesome-icon icon="fa-solid fa-gear" class="icon" />

              <span class="ms-1 d-none d-sm-inline navname">Settings</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/" class="nav-link" @click.prevent="logOut">
              <font-awesome-icon
                icon="fa-solid fa-arrow-right-from-bracket"
                class="icon"
              />
              <span class="ms-1 d-none d-sm-inline navname">LogOut</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

   <!-- My Top Nav -->

    <div class="container-fluid nav-cont bg-dark">
      <div class="row">
        <div class="col p-2">
          <button
            class="btn btn-sm float-end btn-secondary mx-2"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvas"
            role="button"
            @click="toggleOffcanvas"
          >
            <font-awesome-icon
              icon="fa-solid fa-bars"
              class=""
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas"
            />
          </button>

          <div class="logo-container d-flex align-items-center">
            <img
              src="../assets/pinsbg.png"
              alt="PINS ENTERTAINMENT logo"
              class="logo-img mx-3"
              width="40"
              height="40"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  computed: {

    // I am checking if user is logged in

    loggedIn() {
      return this.$store.state.loggedIn;
    },
  },

  methods: {

    // I am calling the logout function
    
    async logOut() {
      const token = this.$store.state.token;
      const email = this.$store.state.email;
      const password = this.$store.state.password;

      try {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}logout`,
          {
            email,
            password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.$store.commit("clearUserData");
        this.$router.push({ name: "login" });
      } catch (error) {
        console.error(error);
      }
    },


  //The removing the overflow style when toggling the side Nav
    toggleOffcanvas() {
      const body = document.body;

      if (body.style.overflow === 'hidden') {
        body.style.overflow = '';
        body.style.paddingRight = '';
      } else {
        body.style.overflow = 'hidden';
        body.style.paddingRight = '19px';
      }
    },
  },
};
</script>

<style scoped>
.bod {
  border: 2px solid green;
}

.nav-cont {
  height: 7vh;
  position: fixed;
  z-index: 1000;
}

.icon {
  color: black;
}

.nav-item {
  color: black;
}

.navname {
  color: black;
}

.navname:hover {
  color: #f9c54d;
}

</style>