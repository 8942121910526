import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import store from '../store/index';
import settings from '../views/Settings.vue'
import Outbox from '../views/Outbox.vue'
import Completed from '../views/Completed.vue'



//Checking if token is set and store it

function isLoggedIn() {

  const token = localStorage.getItem('token');


  if (token) {
    store.dispatch('setToken', token);
    return true;
  } else {
    return false;
  }

}

isLoggedIn();


//My routes, i check is user is authenticated if false I return user to login route

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAuthenticated) {
        next();
      } else {
        return next({
          name: "Dashboard"
        });
      }
    }
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        return next({
          name: "login"
        });
      }
    }

  },
  {
    path: '/settings',
    name: 'Settings',
    component: settings,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        return next({
          name: "login"
        });
      }
    }

  },
  {
    path: '/recents',
    name: 'Completed',
    component: Completed,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        return next({
          name: "login"
        });
      }
    }

  },
  {
    path: '/outbox',
    name: 'Outbox',
    component: Outbox,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        return next({
          name: "login"
        });
      }
    }

  }

  // }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
