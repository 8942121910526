<template>
  <Navigation />
  <div class="container">
     <div class="d-flex justify-content-center align-items-center">
      <div class="mt-5 loader" v-if="isLoading"></div>
    </div>
   
    <div class="main-content card mb-4" v-if="loaded">
      
      <h2 class="text-center mt-3">Recents</h2>

      <div class="card-body tabledata">
        <div class="form-group row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              v-model="searchTerm"
              placeholder="Search"
            />
          </div>
          <div class="col-auto"></div>
        </div>

        <table class="table mt-3 table-responsive-sm">
          <thead>
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>Date</th>
              <th>Notes</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, index) in paginatedFilteredwaitlist"
              v-bind:key="index"
            >
              <td>{{ item.id }}</td>
              <td>
                <div class="player">
                  {{ item.name }}
                </div>
                <div
                  class="
          "
                >
                  {{ item.mobile }}
                </div>
              </td>
              <td>{{ extractTime(item.arrival_time) }}</td>
              <td>Serve Duration: {{ item.wait_time }} Mins</td>

              <td>
                <button
                  @click="
                    requeue(item.mobile, item.arrival_time, item.wait_time)
                  "
                  class="nav-link btn btn-success w-100"
                  title="Requeue"
                >
                  <div class="icon-wrapper ab">
                    <font-awesome-icon
                      icon="fa-solid fa-check"
                      class="mx-1"
                    />Requeue
                  </div>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-nav"
            @click="currentPage--"
            :disabled="currentPage === 1"
          >
            Previous
          </button>
          <span
            >{{ currentPage }} of
            {{ Math.ceil(filteredwaitlist.length / itemsPerPage) }}</span
          >
          <button
            class="btn btn-nav"
            @click="currentPage++"
            :disabled="
              currentPage === Math.ceil(filteredwaitlist.length / itemsPerPage)
            "
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../components/Navigation.vue";

import axios from "axios";

export default {
  computed: {
    filteredwaitlist() {
      if (!Array.isArray(this.waitListData)) {
        return [];
      }
      const searchTermLower = this.searchTerm.toLowerCase();
      const filtered = this.waitListData.filter((item) => {
        const nameLower = item.name.toLowerCase();
        return (
          nameLower.includes(searchTermLower) ||
          item.mobile.includes(this.searchTerm)
        );
      });
      return filtered.slice().reverse();
    },

    paginatedFilteredwaitlist() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredwaitlist.slice(start, end);
    },
  },
  components: {
    Navigation,
  },
  data() {
    return {
      name: "",
      arrivalTime: "",
      waitTime: "",
      remTime: "",
      player: "",
      mobile: "",
      waitListData: [],

      searchTerm: "",
      currentPage: 1,
      itemsPerPage: 6,
      isLoading: "",
      loaded:"",
      apiResponse:"",
      errorResponse:""
    };
  },

  mounted() {
    this.fetchRecents();
  },

  methods: {
    async fetchRecents() {
      this.isLoading = true;
      this.loaded = false;
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}recents`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.isLoading = false;
        this.loaded=true;
        this.waitListData = response.data;
      } catch (error) {
        this.recentsErrorAlert()
        this.isLoading = false
        this.loaded = true
      }
    },

    async requeue(mobile, arrivalTime, waitTime) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}requeue`,
          {
            mobile: mobile,
            arrival_time: arrivalTime,
            wait_time: waitTime,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.requeueAlert();
        this.apiResponse = response.data
        console.log(this.apiResponse)
      } catch (error) {
        
         if(error.response){
         this.errorResponse = error.response.data
         this.errorAlert();

        }else{
        this.errorAlertTwo()
        }
  
      }
    },



    //return just the time  
    extractTime(datetime) {
      const parts = datetime.split(" ");
      const time = parts[0];
      return time;
    },

    //alerts

     requeueAlert() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title: "Pins Notification",
        text: "Added Back to Waitlist",
      });
    },

    errorAlert(){
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6000,
        icon: "warning",
        title: "API ERROR",
        text: `${JSON.stringify(this.errorResponse.error)}`,
        showCancelButton: 'true'
      });

    },

    errorAlertTwo(){
       this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6000,
        icon: "warning",
        title: "API ERROR",
        text: "Something went wrong",
        showCancelButton: 'true'
      });

    },

     recentsErrorAlert(){
       this.$swal({
         toast: true,
         position: 'top-end',
         showConfirmButton: false,
         timer: 6000,
         icon: 'warning',
         title: 'ERROR',
         text: 'Something went wrong fetching recent data',
        showCancelButton: 'true'
       });
     }
  },
};
</script>

<style scoped>
.bod {
  border: 2px solid red;
}
.main-content {
  margin-top: 10%;
}

.tabledata {
  overflow-y: auto;
  max-width: 100%;
}

h2,
th {
  font-family: Raleway, sans-serif;
  font-weight: 700;
}

.btn-nav {
  background-color: #f9c54d;
  color: white;
}

.btn.btn-nav:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

th,
td {
  font-size: smaller;
}
.resend {
  padding: 5px 20px;
  font-size: smaller;
  font-weight: bold;
}

.btn.resend:hover {
  color: #008080;
}

.ab:hover {
  color: white;
}


</style>